.ReactJsonSyntaxHighlighter pre {
    margin: 0;
    font-size: 9pt;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    max-height: 200px;
    overflow: auto;
}

.ReactJsonSyntaxHighlighter pre .string {
    overflow-wrap: anywhere;
    color: #5AB260;
    max-height: 200px;
    overflow-y: auto;
}
.ReactJsonSyntaxHighlighter pre .number {
    color: #FB648C;
}
.ReactJsonSyntaxHighlighter pre .boolean {
    color: #1787F3;
}
.ReactJsonSyntaxHighlighter pre .null {
    color: #FBC32D;
}
.ReactJsonSyntaxHighlighter pre .key {
    color: #FA9B27;
}
